<template>
  <div class="header active">
    <div class="header-top">
      <!-- <router-link
        to="/"
        v-if="$i18n.locale == 'zh'"
        class="logo"
      ></router-link> -->
      <router-link to="/" class="logo"></router-link>
      <div class="header-translate">
        <p
          :class="$i18n.locale != 'en' ? 'header-p1' : ''"
          @click="changeLanguage('zh')"
        >
          中文
        </p>
        <p class="header-xie">/</p>
        <p
          :class="$i18n.locale == 'en' ? 'header-p1' : ''"
          @click="changeLanguage('en')"
        >
          EN
        </p>
      </div>
      <div class="openNav" @click="navOpen"></div>
    </div>
    <div class="nav" :class="{ navCut: navShow }" @click="navClose">
      <div class="nav-hz" @click.stop>
        <el-menu
          default-active="1"
          class="el-menu-vertical-demo"
          router
          :unique-opened="true"
          @open="handleOpen"
          @close="handleClose"
          text-color="#fff"
          active-text-color="#fff"
          @select="select"
        >
          <!-- 关与乐团 -->
          <el-submenu index="1">
            <span slot="title">{{ $t("v2headline1.name") }}</span>
            <!-- 乐团简介 -->
            <el-menu-item-group>
              <el-menu-item index="/orchestra" class="nav-item">{{
                $t("v2headline1.intro")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 乐团艺术家 -->
            <el-menu-item-group>
              <el-menu-item index="/artist" class="nav-item">{{
                $t("v2headline1.artist.name")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 乐团领导 -->
            <el-menu-item-group>
              <el-menu-item index="/orchestraLeader" class="nav-item">{{
                $t("v2headline1.orchestraLeader")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 艺术顾问 -->
            <el-menu-item-group>
              <el-menu-item index="/consultant" class="nav-item">{{
                $t("v2headline1.artisticConsultant")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 经营管理团队 -->
            <el-menu-item-group>
              <el-menu-item index="/team" class="nav-item">{{
                $t("v2headline1.ExecutiveManagementTeam")
              }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 乐团演出 -->
          <el-submenu index="2">
            <span slot="title">{{ $t("v2headline2.name") }}</span>
            <!-- 音乐季 -->
            <el-menu-item-group>
              <el-menu-item index="/music" class="nav-item">{{
                $t("v2headline2.musicSeason")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 国际巡演 -->
            <el-menu-item-group>
              <el-menu-item index="/othershow" class="nav-item">
                <!-- <a
                  href="https://www.suco-europe-tour.com/"
                  style="color: rgba(255,255,255,0.8)"
                  target="_blank"
                > -->
                {{ $t("v2headline2.internationalTour") }}
                <!-- </a> -->
              </el-menu-item>
            </el-menu-item-group>
            <!-- 委约作品 -->
            <el-menu-item-group>
              <el-menu-item index="/works" class="nav-item">{{
                $t("v2headline2.weiYaoWorks")
              }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 乐团资讯 -->
          <el-submenu index="3">
            <span slot="title">{{ $t("v2headline3.name") }}</span>
            <!-- 大事记 -->
            <el-menu-item-group>
              <el-menu-item index="/chronicleOfEvents" class="nav-item">{{
                $t("v2headline3.chronicleOfEvents")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 乐团新闻 -->
            <el-menu-item-group>
              <el-menu-item index="/news" class="nav-item">{{
                $t("v2headline3.orchestraNews")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 团讯 -->
            <!-- <el-menu-item-group>
              <el-menu-item index="/tx" class="nav-item">{{
                $t("v2headline3.tx")
              }}</el-menu-item>
            </el-menu-item-group> -->
          </el-submenu>
          <!-- 艺术教育 -->
          <el-submenu index="4" v-if="$i18n.locale == 'zh'">
            <span slot="title">{{ $t("v2headline4.name") }}</span>
            <!-- 艺术培训 -->
            <el-menu-item-group>
              <el-menu-item index="/training?type=1" class="nav-item">{{
                $t("v2headline4.artTraining")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 社会艺术水平考级 -->
            <el-menu-item-group>
              <el-menu-item index="/training?type=2" class="nav-item">{{
                $t("v2headline4.SocialArtLevelExamination")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 赛事 -->
            <el-menu-item-group>
              <el-menu-item index="/training?type=3" class="nav-item">{{
                $t("v2headline4.saishizhanyan")
              }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 演出购票 -->
          <el-menu-item-group index="5">
            <el-menu-item index=""
              ><a
                href="https://www.sucoch.com/#/"
                style="color: #fff"
                target="_blank"
                >{{ $t("v2headline5") }}</a
              ></el-menu-item
            >
          </el-menu-item-group>
          <!-- 联系我们 -->
          <el-submenu index="6">
            <span slot="title">{{ $t("v2headline6.name") }}</span>
            <!-- 加入我们 -->
            <el-menu-item-group>
              <el-menu-item index="/join" class="nav-item">{{
                $t("v2headline6.joinus")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 商务合作 -->
            <el-menu-item-group>
              <el-menu-item index="/contact" class="nav-item">{{
                $t("v2headline6.businessCooperation")
              }}</el-menu-item>
            </el-menu-item-group>
            <!-- 租借乐谱 -->
            <el-menu-item-group v-if="$i18n.locale == 'zh'">
              <el-menu-item index="/loanSheetMusic" class="nav-item">{{
                $t("v2headline6.loanSheetMusic")
              }}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 国家公共文化云 -->
          <el-menu-item-group index="7">
            <el-menu-item index="">
              <a
                href="https://app.culturedc.cn/thematic/suminle/index.html"
                style="color: #fff"
                target="_blank"
                >{{ $t("v2headline7") }}</a
              ></el-menu-item
            >
          </el-menu-item-group>
          <!-- 联合会 -->
          <el-menu-item-group index="8">
            <el-menu-item index="/league">
              {{ $t("v2headline2.Lianhe") }}</el-menu-item
            >
          </el-menu-item-group>
          <!-- 苏文投集团 -->
          <el-menu-item-group index="9">
            <el-menu-item index="">
              <a
                href="http://www.scidg.com.cn/#/mobile/index"
                style="color: #fff"
                target="_blank"
              >
                {{ $t("v2headline8") }}
              </a></el-menu-item
            >
          </el-menu-item-group>
        </el-menu>
        <div class="navClose" @click="navClose"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      navShow: false,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    select() {
      this.navClose();
    },
    navOpen() {
      this.navShow = !this.navShow;
    },
    navClose() {
      this.navShow = false;
    },
    changeLanguage(type) {
      if (this.$i18n.locale == type) return; //当前模式和点击按钮一样不做处理
      this.$i18n.locale = type; //设置中英文模式
      localStorage.setItem("languageSet", this.$i18n.locale); //将用户设置存储到localStorage以便用户下次打开时使用此设置
      location.reload(); //中英切换刷新当前页面
    },
  },
};
</script>


<style>
.nav .el-menu {
  border: none;
  width: 100%;
  background-color: rgba(22, 22, 22, 0.9) !important;
}
.nav .el-menu .el-submenu__title {
  background-color: rgba(22, 22, 22, 0.9) !important;
  padding-left: 20px !important;
}
.nav .el-menu-item {
  background-color: rgba(22, 22, 22, 0.9) !important;
  padding: 0 20px !important;
  width: 100%;
  box-sizing: border-box;
}
.nav .is-active {
  background-color: rgba(22, 22, 22, 0.9) !important;
}
.nav .el-menu-item-group__title {
  padding: 0 !important;
}
.nav .el-menu-item-group .el-menu-item {
  padding: 0 20px !important;
  width: 100% !important;
  box-sizing: border-box;
}
/* .nav-hz .el-submenu .el-submenu__title{
  border-bottom:1px solid rgba(255,255,255,0.3);
} */
/* .nav-hz .el-menu-item-group{
  border-bottom:1px solid rgba(255,255,255,0.3);
} */
</style>

<style scoped>
.nav-item {
  color: rgba(255, 255, 255, 0.8) !important;
}
.header {
  /* background-image: linear-gradient(rgba(255, 255, 255, .99), rgba(255, 255, 255, .9)); */
  background-color: #634636;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  height: 50px;
}
.header.active {
  background-color: #634636;
  /* background-image: linear-gradient(
    rgba(51, 51, 51, 0.9),
    rgba(51, 51, 51, 0.8)
  ); */
}
.header-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
}
.header-xie {
  font-size: 20px;
  margin: 0 5px;
}
.header-translate {
  display: flex;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  align-items: center;
}
.header-p1 {
  color: rgba(255, 255, 255, 1);
}
.bai {
  color: #fff;
}
.logo {
  width: 122px;
  height: 30px;
  background: url(../assets/logo.png) no-repeat 0 -30px / 100% auto;
  /* margin: 10px 0 0 20px; */
  /* display: inline-block; */
}
.logo2 {
  width: 122px;
  height: 30px;
  background: url(../assets/logoE.png) no-repeat;
  background-size: 100%;
}
.header.active .logo {
  background: url(../assets/logo-1.png) no-repeat 0 0 / 100% auto;
}
.header.active .openNav {
  background: url(../assets/navIcon.png) no-repeat 0 0 / 100% auto;
}
.openNav {
  width: 26px;
  height: 22px;
  background: url(../assets/navIcon.png) no-repeat 0 -30px / 100% auto;
  /* position: absolute; */
  /* right: 20px; */
  /* top: 13px; */
}
.nav-hz {
  width: 60%;
  height: 100%;
  position: relative;
  background: rgba(22, 22, 22, 0.9);
  z-index: 999;
  overflow: hidden;
}
.nav {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  z-index: 88;
  background: transparent;
  color: #fff;
  margin: 0 100% 0 -100%;
  -webkit-transition: 0.2s ease-in 0s;
  -o-transition: 0.2s ease-in 0s;
  transition: 0.2s ease-in 0s;
}
.nav.navCut {
  margin: 0;
}
.nav a {
  text-decoration: none;
}
.nav a.router-link-exact-active {
  color: red;
}

.nav ul,
.nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navSon {
  border-left: 1px solid #b9babb;
  vertical-align: top;
  display: block;
  padding: 10px 0 0 20px;
  margin: 26px 0 0 12px;
  position: relative;
  height: 0;
  max-height: 0;
  opacity: 0;
  -webkit-transition: 0.2s ease-in 0s;
  -o-transition: 0.2s ease-in 0s;
  transition: 0.2s ease-in 0s;
}
.cut .navSon {
  height: auto;
  max-height: 400px;
  opacity: 1;
}
.navSon::after {
  width: 6px;
  height: 6px;
  display: block;
  content: "";
  border-radius: 100%;
  background: #b49a71;
  position: absolute;
  top: -3px;
  left: -3px;
}
.navSon a {
  display: block;
  font-size: 12px;
  padding: 4px 0;
  color: #fff;
}
.nav a.router-link-exact-active {
  color: #b49a71;
}

.navClose {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 10px;
  left: 50%;
}
.navClose::after {
  width: 1px;
  height: 36px;
  content: "";
  display: block;
  background: #fff;
  border-radius: 1px;
  position: absolute;

  -ms-transform: rotate(45deg); /* IE 9 */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
}
.navClose::before {
  width: 1px;
  height: 36px;
  content: "";
  display: block;
  background: #fff;
  border-radius: 1px;
  position: absolute;

  -ms-transform: rotate(-45deg); /* IE 9 */
  -moz-transform: rotate(-45deg); /* Firefox */
  -webkit-transform: rotate(-45deg); /* Safari 和 Chrome */
  -o-transform: rotate(-45deg);
}
</style>