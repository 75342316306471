<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div
      class="pageMain contactBg"
      :style="{ minHeight: clientHeight - 50 + 'px' }"
    >
      <div class="p20">
        <div class="contactBox">
          <div class="contactText">
            {{$t('business.title')}}
          </div>
          <el-row :gutter="20" class="pt30">
            <el-col :span="5">
              <img src="@/assets/flogo.png" width="46" />
            </el-col>
            <el-col :span="19">
              <div class="f16">{{$t('business.contact')}}</div>
              <div class="pt20">
                <div class="contactRow">
                  <img
                    src="@/assets/mapB.png"
                    width="12"
                  />{{$t('business.location')}}
                </div>
                <div class="contactRow">
                  <img
                    src="@/assets/emailB.png"
                    width="12"
                  />sucoh@suzhousco.com
                </div>
                <div class="contactRow">
                  <img src="@/assets/telB.png" width="12" />0512-69589013
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="pt30 tc">
            <el-row :gutter="20" style="max-width: 200px; margin: 0 auto">
              <el-col :span="6" class="tc">
                <a href="#"><img src="@/assets/wx.png" width="24" /></a>
              </el-col>
              <el-col :span="6" class="tc">
                <a href="#"><img src="@/assets/yt.png" width="24" /></a>
              </el-col>
              <el-col :span="6" class="tc">
                <a href="#"><img src="@/assets/dy.png" width="24" /></a>
              </el-col>
              <el-col :span="6" class="tc">
                <a href="#"><img src="@/assets/wb.png" width="24" /></a>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="copyright pt20">
          {{$t('footer.company')}}{{$t('footer.record')}}
          <!-- 2017苏州民族管弦乐团有限公司. all rights reserved. 苏ICP
          备17070606号-1 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
export default {
  name: "App",
  components: {
    Nav,
  },
  data() {
    return {
      isIndex: false,
      clientHeight: 0,
    };
  },
  computed: {},
  mounted() {
    this.clientHeight = document.documentElement.clientHeight;

    window.onresize = () => {
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
      })();
    };
  },
  methods: {
    // getCompanys(){
    // }
  },
};
</script>

<style lang="scss" scoped>
.contactRow {
  font-size: 11px;
  font-weight: 200;
  padding: 6px 0 0 0;
}
</style>

